import React from 'react';
import { Spin } from 'antd';
import css from './about.module.scss';
import { Layouts, CardItem, Banner } from '../components/components';
import { Promo, Study } from '../modules/modules';
import { CardItemType, PromoBlockType } from '../lib/constants/enums';
import { StrapiBlocksName, StrapiMainPagesNames } from '../lib/constants/strapi';
import { replaceStrapiComponentName } from '../lib/utils';
import { useStrapiPage } from '../lib/hooks/useStrapiPage';
import style from '../components/Banner/style.module.scss';
import { Carousel } from '../components/Carousele/Carousel';

const AboutPage = () => {
  const { IndexLayout, Page, Container, LayoutBG } = Layouts;
  const { currentPage } = useStrapiPage(StrapiMainPagesNames.about);
  return (
    <IndexLayout>
      <Page>
        {currentPage && currentPage.blocks ? (
          currentPage.blocks.map((component, index) => {
            switch (replaceStrapiComponentName(component.__component)) {
              case StrapiBlocksName.banner:
                return (
                  <LayoutBG className={style.defaultBannerWrap} background="#F9FAFB">
                    <Container className={style.container}>
                      <Banner banner={component.Slide[0]}/>
                    </Container>
                  </LayoutBG>
                );
              case StrapiBlocksName.carouseBanner:
                return (
                  <Carousel slides={component.CarouselBannerSlide}/>
                );
              case StrapiBlocksName.aboutSplash:
                const promo = currentPage.blocks.find(item => item.__component === 'blocks.promo');

                return (
                  <Promo
                    data={{ title: component?.title, description: component?.description }}
                    type={PromoBlockType.about}
                    imagePosition="right"
                    key={index}
                    promo={{ title: promo.mainTitle, text: promo.additionalDescription }}
                  />
                );
              case StrapiBlocksName.study:
                return <Study data={component} key={index}/>;
              case StrapiBlocksName.aboutList:
                return (
                  <LayoutBG className={css.awardsBlock} key={index}>
                    <Container className={css.awardsContainer}>
                      <h2>{component?.title}</h2>
                      <h3>{component?.secondTitle}</h3>
                      <div className={css.awardsList}>
                        {component?.aboutItems &&
                        component?.aboutItems.length !== 0 &&
                        component.aboutItems.map((award, index) =>
                          component?.aboutItems!.length - 1 === index ? (
                            <CardItem
                              width="100%"
                              type={CardItemType.awards}
                              description={award?.description || ''}
                              image={award?.picture?.url || ''}
                              key={award?.id || index}
                              last
                            />
                          ) : (
                            <div className={css.awardItem} key={award?.id || index}>
                              <CardItem
                                type={CardItemType.awards}
                                description={award?.description || ''}
                                image={award?.picture?.url || ''}
                              />
                            </div>
                          ),
                        )}
                      </div>
                    </Container>
                  </LayoutBG>
                );
              default:
                return null;
            }
          })
        ) : (
          <div className={css.loader}>
            <Spin/>
          </div>
        )}
      </Page>
    </IndexLayout>
  );
};

export default AboutPage;
