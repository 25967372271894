import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Autoplay, Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import style from './style.module.scss';

import './carousel.scss';
import Button from '../Button/Button';


interface Props {
  slides: Slide[];
}

interface Slide {
  buttonText: string;
  buttonUrl: string;
  subtitle: string;
  title: string;
  gradient?: string;
  image: {
    url: string
  };
  textColor?: string;
  imagePosition?: string;
  imageWrapperMinHeight?: string;
}


export const Carousel = (props: Props) => {
  return (
    <div className={style.wrapper}>
      <Swiper navigation={true}
              loop={true}
              pagination={props.slides.length > 1}
              modules={[ Autoplay, Pagination, Navigation ]}
              className={style.mySwiper}
              autoplay={props.slides.length > 1 ? ({
                delay: 7000,
                disableOnInteraction: false,
              }) : false}>
        {props.slides.map((slide, index) => {
          const marginOffset = {
            margin: slide.imagePosition === 'Middle' ? 'auto initial' : undefined,
            marginBottom: slide.imagePosition === 'Top' ? 'auto' : undefined,
            marginTop: slide.imagePosition === 'Bottom' ? 'auto' : undefined,
          };

          return (
            <SwiperSlide key={index} className={style.swiperSlide} style={{ background: slide.gradient }}>
              <div className={style.contentWrapper} style={marginOffset}>
                <div className={style.imagePart} style={{ minHeight: slide.imageWrapperMinHeight }}>
                  <img src={process.env.STRAPI_API_URL + slide.image.url} style={marginOffset}/>
                </div>

                <div className={style.textPart}>
                  <h5 className={style.title} style={{ color: slide.textColor }}>{slide.title}</h5>
                  <span className={style.subtitle} style={{ color: slide.textColor }}>{slide.subtitle}</span>
                  <div style={{ width: 'fit-content' }}>
                    <a href={slide.buttonUrl} target="_blank">
                      <Button className={style.button}
                              title={slide.buttonText}
                              from="#6D1F9E"
                              to="#6D1F9E"
                              shadowColor="#6D1F9E"
                              uppercase
                              border
                              bgColor="#6D1F9E"
                              fontColor="#FFFFFF"/>
                    </a>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};
